ion-input.zz-input {
    --ion-font-family: cooper md bt, serif;

    font-size: 18px;
    line-height: 22px;

    &:not(.ignore-screen-based-font-scaling) {
        @include if-small-phone {
            font-size: 14px;
            line-height: 17px;
        }
    }
}
