ion-label.zz-ion-label {
    font-family: 'Helvetica Now Text', sans-serif;

    font-size: 17px;
    line-height: 26px;

    &.s {
        font-size: 15px;
        line-height: 23px;
    }

    &.xs {
        font-size: 13px;
        line-height: 20px;
    }

    &.xxs {
        font-size: 11px;
        line-height: 17px;
    }

    &:not(.ignore-screen-based-font-scaling) {
        @include if-small-phone {
            font-size: 15px;
            line-height: 23px;
        }

        @include if-small-phone {
            &.s {
                font-size: 13px;
                line-height: 20px;
            }
        }

        @include if-small-phone {
            &.xs {
                font-size: 11px;
                line-height: 17px;
            }
        }

        @include if-small-phone {
            &.xxs {
                font-size: 9px;
                line-height: 14px;
            }
        }
    }
}
