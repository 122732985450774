.agent-chat {
    .str-chat {
        .str-chat__list {
            background: url('../../../../../../assets/images/illustrations/chat/chat-background.svg');
            background-size: cover;
        }

        .str-chat__message .str-chat__quoted-message-preview {
            display: none;
        }

        .str-chat__message--me .str-chat__message-text span {
            color: var(--ion-color-snowy);
        }

        .str-chat__message--me .str-chat__message-bubble p {
            color: var(--ion-color-snowy);
        }

        &__empty-channel svg {
            width: calc(var(--str-chat__spacing-px) * 436);
            height: calc(var(--str-chat__spacing-px) * 436);
            background: url('../../../../../../assets/images/charly/charly-sniffs-the-ground-large.svg') no-repeat;
        }

        &__empty-channel-text {
            visibility: hidden;
            white-space: pre-wrap;

            &::before {
                display: block;
                margin-right: auto;
                margin-left: auto;
                color: var(--ion-color-jack);
                content: 'No conversation selected \A ';
                font-family: 'Cooper Md BT', serif;
                font-size: 30px;
                font-weight: bold;
                line-height: 36px;
                visibility: visible;
            }

            &::after {
                display: block;
                margin-right: auto;
                margin-left: auto;
                content: 'Please pick a person from the left hand side menu to start a conversation';
                font-family: var(--ion-font-family, inherit);
                font-size: 15px;
                visibility: visible;
            }
        }
    }
}
