ion-datetime-button.zz-datetime-button {
    &::part(native) {
        width: 100%;
        height: 100%;
        color: var(--ion-color-jack);
        background: transparent;
        border: 1px solid var(--ion-color-disabled-text);
        border-radius: 32px;
        padding: 13px;

        font-family:
            Cooper Md BT,
            serif;
        font-size: 18px;
        line-height: 22px;

        @include if-small-phone {
            font-size: 14px;
            line-height: 17px;
        }

        &:active {
            border: 1px solid var(--ion-color-jack);
            color: var(--ion-color-snowy);
            background: var(--ion-color-jack);
        }
    }
}

ion-datetime-button.datetime-button-new-onboarding {
    &::part(native) {
        width: 100%;
        height: 100%;
        color: var(--ion-color-snowy);
        background: var(--datetime-background, --ion-color-harley);
        border: 1px solid var(--ion-color-snowy);
        border-radius: 12px;
        padding: 12px 48px;

        font-family:
            Cooper Md BT,
            serif;
        font-size: 22px;
        line-height: 24px;
        text-align: left;

        @include if-small-phone {
            font-size: 18px;
            line-height: 26px;
        }
    }
}
