@import '../mixins/media-query.mixin.scss';

ion-button {
    --border-radius: 32px;

    font-family: 'Cooper Md BT', serif;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: 0.4px;

    &.disabled-max-no-opacity[disabled] {
        --ion-color-base: #ffacab !important;
        opacity: 1;
    }

    ion-spinner {
        width: 1em;
        height: 1em;
    }

    ion-icon {
        font-size: 1em;
        padding-left: 4px;
        padding-right: 4px;
    }

    &.plain-text {
        font-family: 'Helvetica Now Text', serif;
    }

    &.s {
        font-size: 14px;
        line-height: 17px;
    }

    &.l {
        font-size: 22px;
        line-height: 26px;
    }

    &:not(.ignore-screen-based-font-scaling) {
        @include if-small-phone {
            font-size: 14px;
            line-height: 17px;
        }

        @include if-small-phone {
            &.s {
                font-size: 10px;
                line-height: 12px;
            }
        }

        @include if-small-phone {
            &.l {
                font-size: 18px;
                line-height: 22px;
            }
        }
    }
}

.ion-nav-button {
    --border-radius: 100%;
    --color: var(--ion-color-jack);
    --box-shadow: 0px;

    width: 40px;
    height: 40px;
    font-size: 22px;

    --padding-start: 5px;
    --padding-end: 5px;
}

.ios ion-toggle {
    margin-right: 16px;
}
