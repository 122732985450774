/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import '@ionic/angular/css/core.css';

/* Basic CSS for apps built with Ionic */
@import '@ionic/angular/css/normalize.css';
@import '@ionic/angular/css/structure.css';
@import '@ionic/angular/css/typography.css';
@import '@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import '@ionic/angular/css/padding.css';
@import '@ionic/angular/css/float-elements.css';
@import '@ionic/angular/css/text-alignment.css';
@import '@ionic/angular/css/text-transformation.css';
@import '@ionic/angular/css/flex-utils.css';

/* Ionic overrides */
@import 'theme/ionic/ion-action-sheet';
@import 'theme/ionic/ion-button';
@import 'theme/ionic/ion-card';
@import 'theme/ionic/ion-input';
@import 'theme/ionic/ion-select';
@import 'theme/ionic/ion-textarea';
@import 'theme/ionic/ion-toast';
@import 'theme/ionic/ion-datetime';
@import 'theme/ionic/ion-datetime-button';
@import 'theme/ionic/ion-label';

/* Global styles */
@import 'theme/circle';
@import 'theme/color';
@import 'theme/color-font';
@import 'theme/fonts';
@import 'theme/footer';
@import 'theme/image';
@import 'theme/modal';
@import 'theme/page';
@import 'theme/typography';
@import 'theme/variables-custom';
@import 'theme/button';
@import 'theme/button-color';
@import 'theme/utility.scss';

/* External styles */
@import '@ctrl/ngx-emoji-mart/picker';
@import 'stream-chat-angular/src/assets/styles/scss/index';

/* ngx-emoji-mart overrides */
@import 'theme/ngx-emoji-mart/ngx-emoji-mart';

/* Stream Chat overrides */
@import 'theme/stream-chat/chat-generic';
@import 'theme/stream-chat/agent-chat';
@import 'theme/stream-chat/ai-chat';
@import 'theme/stream-chat/training-coach-chat';

/* Contentful */
@import 'theme/contentful';

/* Dynamic Content Block */
@import 'theme/dynamic-content-block';
