@import 'variables';
@import 'mixins/media-query.mixin';

//TODO: NBSon - evaluate the naming of the classes of this modifier styles and BEMify them
// we may not want to rely on this and use rem instead, as handling scaling will become difficult e.g. with a span inside a p/h tag

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: 'Cooper Md BT', serif;
}

p,
q {
    font-family: 'Helvetica Now Text', serif;
}

/**
 Headings use -/+ 4 to scale size and line-height is 1.2 * font-size.
 */

h1 {
    font-size: 30px;
    line-height: 36px;

    &.xxxl {
        font-size: 150px;
        line-height: 180px;
    }

    &.xxl {
        font-size: 54px;
        line-height: 65px;
    }

    &.xl {
        font-size: 38px;
        line-height: 46px;
    }

    &.l {
        font-size: 34px;
        line-height: 41px;
    }

    &.s {
        font-size: 26px;
        line-height: 31px;
    }

    &:not(.ignore-screen-based-font-scaling) {
        @include if-small-phone {
            font-size: 26px;
            line-height: 31px;
        }

        @include if-small-phone {
            &.xxxl {
                font-size: 150px;
                line-height: 180px;
            }
        }

        @include if-small-phone {
            &.xxl {
                font-size: 54px;
                line-height: 65px;
            }
        }

        @include if-small-phone {
            &.xl {
                font-size: 38px;
                line-height: 46px;
            }
        }

        @include if-small-phone {
            &.l {
                font-size: 34px;
                line-height: 41px;
            }
        }

        @include if-small-phone {
            &.s {
                font-size: 26px;
                line-height: 31px;
            }
        }
    }
}

h2 {
    font-size: 22px;
    line-height: 26px;

    &.l {
        font-size: 26px;
        line-height: 31px;
    }

    &.s {
        font-size: 18px;
        line-height: 22px;
    }

    &:not(.ignore-screen-based-font-scaling) {
        @include if-small-phone {
            font-size: 18px;
            line-height: 22px;
        }

        @include if-small-phone {
            &.l {
                font-size: 22px;
                line-height: 26px;
            }
        }

        @include if-small-phone {
            &.s {
                font-size: 14px;
                line-height: 17px;
            }
        }
    }
}

h3 {
    font-size: 18px;
    line-height: 22px;

    &.plain-text {
        font-family: 'Helvetica Now Text', serif;
    }

    span {
        &.scale-up {
            font-size: 24px;
            line-height: 29px;
        }
    }

    &.l {
        font-size: 22px;
        line-height: 26px;
    }

    &.s {
        font-size: 14px;
        line-height: 17px;
    }

    &:not(.ignore-screen-based-font-scaling) {
        @include if-small-phone {
            font-size: 16px;
            line-height: 17px;

            span {
                &.scale-up {
                    font-size: 16px;
                    line-height: 19px;
                }
            }
        }

        @include if-small-phone {
            &.l {
                font-size: 18px;
                line-height: 22px;
            }
        }

        @include if-small-phone {
            &.s {
                font-size: 10px;
                line-height: 12px;
            }
        }
    }
}

// this is styled for h4's in contentful
h4 {
    color: var(--ion-color-paragraph-color);
    font-size: 18px;
    line-height: 22px;

    &.l {
        font-size: 22px;
        line-height: 26px;
    }

    &.s {
        font-size: 14px;
        line-height: 17px;
    }

    &:not(.ignore-screen-based-font-scaling) {
        @include if-small-phone {
            font-size: 14px;
            line-height: 17px;
        }

        @include if-small-phone {
            &.l {
                font-size: 18px;
                line-height: 22px;
            }
        }

        @include if-small-phone {
            &.s {
                font-size: 10px;
                line-height: 12px;
            }
        }
    }
}

label.zz-label {
    color: var(--ion-color-paragraph-color);
    font-family: 'Cooper Md BT', serif;
    font-size: 17px;
    line-height: 26px;

    &.plain-text {
        font-family: 'Helvetica Now Text', serif;
    }

    &.l {
        font-size: 19px;
        line-height: 29px;
    }

    &.s {
        font-size: 15px;
        line-height: 23px;
    }

    &:not(.ignore-screen-based-font-scaling) {
        @include if-small-phone {
            font-size: 15px;
            line-height: 23px;
        }

        @include if-small-phone {
            &.l {
                font-size: 17px;
                line-height: 26px;
            }
        }

        @include if-small-phone {
            &.s {
                font-size: 13px;
                line-height: 20px;
            }
        }
    }
}

/**
 Text uses -/+ 2 to scale size and line-height is 1.5 * font-size.
 */
p,
q,
b {
    color: var(--ion-color-paragraph-color);
    font-size: 17px;
    line-height: 26px;

    &.xxl {
        font-size: 24px;
        line-height: 35px;
    }

    &.xl {
        font-size: 22px;
        line-height: 33px;
    }

    &.l {
        font-size: 19px;
        line-height: 29px;
    }

    &.s {
        font-size: 15px;
        line-height: 23px;
    }

    &.xs {
        font-size: 13px;
        line-height: 20px;
    }

    &.xxs {
        font-size: 11px;
        line-height: 17px;
    }

    &:not(.ignore-screen-based-font-scaling) {
        @include if-small-phone {
            font-size: 15px;
            line-height: 23px;
        }

        @include if-small-phone {
            &.xxl {
                font-size: 22px;
                line-height: 33px;
            }
        }

        @include if-small-phone {
            &.xl {
                font-size: 19px;
                line-height: 29px;
            }
        }

        @include if-small-phone {
            &.l {
                font-size: 17px;
                line-height: 26px;
            }
        }

        @include if-small-phone {
            &.s {
                font-size: 13px;
                line-height: 20px;
            }
        }

        @include if-small-phone {
            &.xs {
                font-size: 11px;
                line-height: 17px;
            }
        }

        @include if-small-phone {
            &.xxs {
                font-size: 9px;
                line-height: 14px;
            }
        }
    }
}

.center {
    text-align: center;
}

.text-block {
    padding: 0 20px;

    &.double-padding {
        padding: 0 40px;
    }
}

.text-link {
    display: block;
    color: var(--ion-color-paragraph-color);
    font-size: 15px;
    text-decoration: underline;
    background-color: transparent;
}

.subtitle {
    margin: 6px 0;
}

a {
    color: var(--ion-color-max);
}

.text-snowy {
    color: var(--ion-color-snowy);
}

.text-warning {
    color: var(--ion-color-max);
    font-family: 'Helvetica Now Text', sans-serif;
    font-size: 13px;
}

.ignore-global-color p,
q,
b,
h1,
h2,
h3,
h4,
h5,
h6,
span,
ul,
li {
    color: unset;
}
