.training-coach-chat {
    .str-chat {
        .str-chat__list {
            background: url('../../../../../../assets/images/illustrations/chat/chat-background.svg');
            background-size: cover;
        }

        .str-chat__message--me .str-chat__message-text span {
            color: var(--ion-color-snowy);
        }

        .str-chat__message--me .str-chat__message-bubble p {
            color: var(--ion-color-snowy);
        }
    }
}
